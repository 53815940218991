<template>
    <el-upload :show-file-list="false" :http-request="request" :on-change="onChange" :drag="true"
        :disabled="isDisabled">
        <template #trigger>
            <div class="img" v-if="imgUrl" v-loading="loading">
                <el-image :src="imgUrl" />
            </div>
            <el-icon class="uploadIcon" v-else v-loading="loading">
                <span v-if="isShowMsg" style="color:#c45656">上传失败!</span>
                <span v-else>
                    <Upload /><span style="font-size:10px">{{ text }}</span>
                </span>
            </el-icon>
        </template>
    </el-upload>
</template>

<script setup>

import { ref, defineEmits, defineProps, defineExpose, getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps(['img', 'text', 'onlyUrl', 'isDisabled'])
const emit = defineEmits(["success"])
const imgUrl = ref('')
const text = ref('')
const isOnlyUrl = ref(false)
const loading = ref(false)
const isShowMsg = ref(false)

imgUrl.value = props.img ? props.img : ''
text.value = props.text ? props.text : ''
isOnlyUrl.value = props.onlyUrl ? true : false

const request = (options) => {
    if (isOnlyUrl.value) {
        proxy.$api.uploadImageOnlyUrl({ file: options.file }, { headers: { 'Content-Type': 'multipart/form-data' } }).then(r => {
            if (r.status == 200) {
                loading.value = false
                imgUrl.value = r.data.data[0]
                emit("success", imgUrl.value);
            }
        }).catch(() => {
            loading.value = false
            isShowMsg.value = true
        })
    } else {
        proxy.$api.uploadImage({ file: options.file }, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((r) => {
                if (r.data.status == 200) {
                    loading.value = false
                    const url = URL.createObjectURL(options.file);
                    imgUrl.value = url
                    emit("success", r.data.data.id, url, options.file);
                }
            }).catch(() => {
                loading.value = false
                isShowMsg.value = true
            })
    }
}

const onChange = (r) => {
    if (r.status == "ready") {
        isShowMsg.value = false
        loading.value = true
    }
}

const setImgUrl = (url) => {
    imgUrl.value = url
}

defineExpose({ setImgUrl })
</script>

<style lang="less">
.el-loading-spinner {
    margin-top: -36px !important;
}

.uploadIcon,
.img {
    @width: 100px;
    display: block;
    width: @width;
    height: @width;
    text-align: center;
    line-height: @width;
    background-color: var(--el-fill-color-lighter);
    border: 1px dashed var(--el-border-color-darker);
    font-size: 12px;
    color: rgb(91, 91, 91)
}
</style>