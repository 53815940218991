export function phone(rules, value, callback) {
    const reg = /\d+/
    if (value.charAt(0) != 1) {
        callback(new Error('手机号格式错误'))
    } else if (value.length != 11) {
        callback(new Error('手机号必须是11位'))
    } else if (!reg.test(value)) {
        callback(new Error('手机号码有误'))
    } else {
        callback()
    }
}

export function identificationNumber(rule, value, callback) {
    const reg = /^([A-Z]|\d)+$/
    if (value.length < 15 || value.length > 18) {
        callback(new Error('证件号必须是15或者18位'))
    } else if (!reg.test(value)) {
        callback(new Error('证件号格式不正确'))
    } else {
        callback()
    }


}

export function bankNumber(rule, value, callback) {
    const reg = /^\d+$/
    if (value.length < 1 || value.length > 19) {
        callback(new Error('银行卡号必须是6或者19位'))
    } else if (!reg.test(value)) {
        callback(new Error('银行卡号码错误'))
    } else {
        callback()
    }
}

export function checkRate(rule, value, callback) {
    if ('' == rule.field) {
        callback(new Error('费率必填'))
    } else if (Number(rule.field) > 100 || Number(rule.field) < 0) {
        callback(new Error('费率值错误'))
    } else {
        callback()
    }
}