export function timeFormat(time = '', format = 'date') {
    const separator = '-'

    let date
    if (time) {
        date = new Date(time)
    } else {
        date = new Date()
    }

    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    if (format == "date") {
        return Y + separator + m + separator + d
    }

    if (format == 'datetime') {
        return Y + separator + m + separator + d + " " + H + ":" + i + ":" + s
    }

    if (format == 'string') {
        return Y + m + d + H + i + s
    }
}

export function getFullTime(time) {
    return time < 10 ? "0" + time.toString() : time.toString()
}

export function getTodayStartAndEnd() {
    const date = new Date()
    const y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const h = getFullTime(date.getHours())
    const M = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    return [
        y + '-' + m + '-' + d + ' 00:00:00',
        y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s,
    ]
}

export function getTodayStartEnd() {
    const date = new Date()
    const y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    return [
        y + '-' + m + '-' + d,
        y + '-' + m + '-' + d
    ]
}


export function getTodayStartAndEnds() {
    const date = new Date()
    const y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())

    return [
        y + '-' + m + '-' + d + ' 00:00:00',
        y + '-' + m + '-' + d + ' 23:59:59'
    ]
}